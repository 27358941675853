<template>
    <div class="w-100 border mt-2">
        <table class="table table-hover table-bordered m-0 p-0">
            <thead>
                <tr class="table-primary">
                    <th class="w-50 p-0">
                        <div
                            class="d-flex justify-content-center align-items-center table-height-80"
                        >
                            <span>Nombre</span>
                        </div>
                    </th>
                    <th class="w-50 p-0">
                        <div
                            class="d-flex justify-content-center align-items-center table-height-80"
                        >
                            <span>Valor</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in itemsCurrent" :key="index">
                    <th class="text-center font-weight-normal">
                        <vs-input
                            v-model="item.name"
                            border
                            shadow
                            :state="stateEnabled"
                            type="text"
                            class="w-100 bg-base text-base"
                            autocomplete="off"
                            :disabled="!enabled"
                        />
                    </th>
                    <th>
                        <vs-input
                            v-model="item.value"
                            border
                            shadow
                            :state="stateEnabled"
                            type="text"
                            class="w-100 bg-base text-base"
                            autocomplete="off"
                            :disabled="!enabled"
                        />
                    </th>
                    <th v-if="enabled" class="p-0 m-0">
                        <button
                            class="btn d-flex p-0 h-20 align-items-center justify-content-center"
                            @click="deleteItem(index)"
                        >
                            <small> ❌ </small>
                        </button>
                    </th>
                </tr>
            </tbody>
        </table>

        <div v-if="enabled" class="d-flex justify-content-center" colspan="2">
            <vs-button icon border @click="addNewItem"> ➕ </vs-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "FeatureManage",
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            type: Array,
            default: () => [{ name: "", value: "" }],
            required: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    computed: {
        ...mapGetters("control", ["stateInputDark"]),
        stateEnabled() {
            return this.enabled ? this.stateInputDark : "";
        }
    },
    mounted() {
        this.setInput(this.itemsCurrent);
    },
    methods: {
        addNewItem() {
            let itemsResult = [...this.itemsCurrent];
            itemsResult.push({ name: "", value: "" });
            this.$emit("change", itemsResult);
        },
        deleteItem(fromIndex) {
            let itemsResult = [...this.itemsCurrent];
            itemsResult.splice(fromIndex, 1);
            this.$emit("change", itemsResult);
        },
        setInput(value) {
            let itemsResult = JSON.parse(JSON.stringify(value));
            if (!Array.isArray(itemsResult)) {
                itemsResult = [];
            }
            this.$emit("change", itemsResult);
        }
    }
};
</script>
