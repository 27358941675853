<template>
    <div class="my-2 mx-2 w-100">
        <vs-card-group v-if="loadLines">
            <vs-card v-if="itemCurrent.line">
                <template #title>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <a
                            :href="`/app/lines/detail/${itemCurrent.line.slug}`"
                            target="_blank"
                            rel="noopener"
                        >
                            <h3 class="text-main mx-80">
                                {{ itemCurrent.line.name }}
                            </h3>
                        </a>

                        <div class="d-flex">
                            <vs-button
                                v-if="enabled"
                                :disabled="isLoading"
                                icon
                                @click="openLineSelector"
                            >
                                🖊️
                            </vs-button>
                        </div>
                    </div>
                </template>
                <template #text>
                    <div class="h-10">
                        <span>
                            {{ itemCurrent.line.shortDescription | cut(40) }}
                        </span>
                    </div>
                </template>
            </vs-card>
            <vs-card>
                <template #title>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <a
                            :href="`/app/${
                                itemCurrent.line ? 'sublines' : 'lines'
                            }/detail/${itemCurrent.slug}`"
                            target="_blank"
                            rel="noopener"
                        >
                            <h3 class="text-main mx-80">
                                {{ itemCurrent.name }}
                            </h3>
                        </a>

                        <div class="d-flex">
                            <vs-button
                                v-if="enabled"
                                :disabled="isLoading"
                                icon
                                @click="openSublineLineSelector"
                            >
                                🖊️
                            </vs-button>
                            <vs-button
                                v-if="enabled && itemCurrent.line"
                                :disabled="isLoading"
                                danger
                                icon
                                @click="deleteSubline"
                            >
                                🗑️
                            </vs-button>
                        </div>
                    </div>
                </template>
                <template #text>
                    <div class="h-10">
                        <span>
                            {{ itemCurrent.shortDescription | cut(40) }}
                        </span>
                    </div>
                </template>
            </vs-card>
            <vs-card v-if="enabled && !itemCurrent.line">
                <template #title>
                    <h3 class="mx-80">Agregar sublínea</h3>
                </template>
                <template #text>
                    <div class="h-10 d-flex justify-content-center">
                        <button
                            type="button"
                            class="btn"
                            @click="openSublineSelector"
                        >
                            <h2>➕</h2>
                        </button>
                    </div>
                </template>
            </vs-card>
        </vs-card-group>

        <div v-if="enabled" class="row my-3">
            <div class="col-12 d-flex justify-content-center">
                <vs-button
                    :disabled="!itemCurrent.slug"
                    :loading="isLoading"
                    @click="updateSublineOfProduct"
                >
                    Actualizar líneas
                </vs-button>
            </div>
        </div>

        <div>
            <vs-dialog
                v-model="isSelectedLine"
                scroll
                overflow-hidden
                auto-width
                :loading="isLoading"
            >
                <template #header>
                    <div class="mx-4 mt-2 d-flex flex-column flex-lg-row">
                        <h3>Seleccione una línea ➖</h3>
                        <vs-button
                            :disabled="!selectedLine"
                            icon
                            circle
                            @click="chooseLine"
                        >
                            ✔️
                        </vs-button>
                    </div>
                </template>
                <div class="con-content">
                    <div
                        class="d-flex flex-column justify-content-center align-items-center"
                    >
                        <button
                            v-for="(item, index) in lines"
                            :key="index"
                            class="d-flex flex-column btn btn-primary w-75 w-min-30 my-2 text-left"
                            :class="
                                item.slug === selectedLine
                                    ? 'border-3 border-highlight'
                                    : ''
                            "
                            @click="selectedLine = item.slug"
                        >
                            <h3>{{ item.name }}</h3>
                        </button>
                    </div>
                </div>
            </vs-dialog>
            <vs-dialog
                v-model="isSelectSubline"
                scroll
                overflow-hidden
                auto-width
                :loading="isLoading"
            >
                <template #header>
                    <div
                        class="mx-4 mt-2 d-flex flex-column flex-lg-row align-items-center"
                    >
                        <h3>
                            Seleccione una sublínea 〰️
                            <br />
                            para la línea
                            {{
                                itemCurrent.line
                                    ? itemCurrent.line.name
                                    : itemCurrent.name
                            }}
                        </h3>
                        <div>
                            <vs-button
                                :disabled="!selectedSubline"
                                icon
                                circle
                                @click="chooseSubline"
                            >
                                ✔️
                            </vs-button>
                        </div>
                    </div>
                </template>
                <div class="con-content">
                    <div
                        class="d-flex flex-column justify-content-center align-items-center"
                    >
                        <button
                            v-for="(item, index) in sublinesByLine"
                            :key="index"
                            class="d-flex flex-column btn w-75 btn-primary w-min-30 my-2 text-left"
                            :class="
                                item.slug === selectedSubline
                                    ? 'border-3 border-highlight'
                                    : ''
                            "
                            @click="selectedSubline = item.slug"
                        >
                            <h3>{{ item.name }}</h3>
                            <p>{{ item.shortDescription }}</p>
                        </button>
                    </div>
                </div>
            </vs-dialog>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "LineSublineManage",
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            type: Object,
            require: true,
            default: () => ({
                slug: "",
                name: "",
                shortDescription: "",
                line: {
                    slug: "",
                    name: "",
                    shortDescription: ""
                }
            })
        },
        productId: {
            default: () => 0,
            type: Number,
            require: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        isSelectedLine: false,
        isSelectSubline: false,
        selectedLine: null,
        selectedSubline: null,
        currentPageLine: 1,
        outdatedSublines: true,
        isLoading: false,
        loadedLines: false,
        sublinesByLine: [],
        lines: []
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor"])
    },
    mounted() {},
    methods: {
        ...mapActions("commons", ["listAllLines"]),
        ...mapActions("sublines", ["listSublinesByLine"]),
        ...mapActions("products", ["updateProductLine"]),
        async openSublineSelector() {
            this.isSelectSubline = true;
            await this.loadSublines(this.itemCurrent.id);
        },
        async openLineSelector() {
            this.isSelectedLine = true;
            await this.loadLines();
        },
        async openSublineLineSelector() {
            if (this.itemCurrent.line) {
                this.isSelectSubline = true;
                await this.loadSublines(this.itemCurrent.line.slug);
            } else {
                this.isSelectedLine = true;
                await this.loadLines();
            }
        },
        async loadLines() {
            this.isLoading = true;
            try {
                this.lines = await this.listAllLines({
                    required: true
                });
            } catch (error) {
                this.isSelectedLine = false;
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async chooseLine() {
            const lineAux = this.lines.find(
                (i) => i.slug === this.selectedLine
            );
            if (lineAux) {
                this.isSelectedLine = false;
                this.$emit("change", {
                    slug: lineAux.slug,
                    name: lineAux.name,
                    shortDescription: lineAux.shortDescription,
                    line: null
                });
                this.outdatedSublines = true;
                await this.loadSublines(lineAux.slug);
            }
        },
        async loadSublines(lineSlug) {
            this.isLoading = true;
            try {
                this.sublinesByLine = await this.listSublinesByLine({
                    lineSlug,
                    required: this.outdatedSublines
                });
                this.isSelectSubline = true;
                this.outdatedSublines = false;
            } catch (error) {
                this.isSelectSubline = false;
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async updateSublineOfProduct() {
            if (this.itemCurrent.id === 0) {
                return this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Línea invalida",
                    text: "Revisa por favor selecciona una línea valida",
                    icon: "info"
                });
            }
            this.isLoading = true;
            try {
                await this.updateProductLine({
                    productId: this.productId,
                    lineSlug: this.itemCurrent.slug
                });

                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        chooseSubline() {
            const sublineAux = this.sublinesByLine.find(
                (i) => i.slug === this.selectedSubline
            );
            if (sublineAux) {
                this.isSelectSubline = false;
                if (this.itemCurrent.line) {
                    this.$emit("change", {
                        slug: sublineAux.slug,
                        name: sublineAux.name,
                        shortDescription: sublineAux.shortDescription,
                        line: this.itemCurrent.line
                    });
                } else {
                    this.$emit("change", {
                        slug: sublineAux.slug,
                        name: sublineAux.name,
                        shortDescription: sublineAux.shortDescription,
                        line: this.itemCurrent
                    });
                }
            }
        },
        deleteSubline() {
            this.$emit("change", {
                slug: this.itemCurrent.line.slug,
                name: this.itemCurrent.line.name,
                shortDescription: this.itemCurrent.line.shortDescription,
                line: null
            });
        }
    }
};
</script>

<style scoped>
.vs-card__group .vs-card__group-cards .vs-card-content {
    min-width: 15rem;
    margin-left: 20px;
}
.vs-navbar-content {
    z-index: 1000 !important;
}
</style>
