<template>
    <div class="w-100 h-100 position-relative">
        <div v-if="enabled" class="d-flex">
            <vs-input
                v-show="isOpenInput"
                ref="inputArrayInput"
                v-model="keyword"
                border
                shadow
                :state="stateInputDark"
                class="w-100 mw-100"
                :label="label"
                @keyup.enter="addNewKeyword"
            />
            <vs-select
                v-show="!isOpenInput"
                v-model="arrayString"
                :label="label"
                multiple
                :state="stateInputDark"
                class="w-100 mw-100 border-custom"
                @input="onInput"
            >
                <vs-option
                    v-for="(item, index) in arrayStringBase"
                    :key="index"
                    :label="item"
                    :value="item"
                >
                    {{ item }}
                </vs-option>
            </vs-select>

            <vs-button icon border @click="changeActionKeys">
                {{ isOpenInput ? "✔️" : "➕" }}
            </vs-button>
        </div>
        <div v-else class="d-flex flex-column h-100 w-100 mw-100">
            <div class="position-relative">
                <label class="left-13px"> {{ label }}s </label>
            </div>

            <div class="d-flex flex-wrap px-2">
                <div v-if="arrayString.length">
                    <div
                        v-for="(item, index) in arrayString"
                        :key="index"
                        class="bg-primary rounded mx-1 mt-1 px-1"
                        :label="item"
                        :value="item"
                    >
                        <small>{{ item }}</small>
                    </div>
                </div>

                <div v-else class="">
                    <span> Sin {{ label }}s </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ArrayStringSelector",
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        label: {
            type: String,
            default: () => "",
            required: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        },
        base: {
            type: Array,
            default: () => [],
            required: false
        },
        itemsCurrent: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    emits: ["change"],
    data: () => ({
        arrayString: [],
        isOpenInput: false,
        keyword: "",
        arrayStringBase: []
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark"])
    },
    watch: {
        itemsCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.arrayStringBase = [...this.base, ...this.itemsCurrent];
        this.setInput(this.itemsCurrent);
        this.isOpenInput = !this.arrayString.length;
    },
    methods: {
        addNewKeyword() {
            if (this.keyword) {
                const arrayString = this.keyword.split(",");
                this.arrayStringBase = [
                    ...arrayString,
                    ...this.arrayStringBase
                ];
                this.arrayString = [...arrayString, ...this.arrayString];
                this.keyword = "";
                this.onInput();
            }
        },
        changeActionKeys() {
            if (this.isOpenInput) {
                this.addNewKeyword();
                this.isOpenInput = false;
            } else {
                this.isOpenInput = true;
                setTimeout(() => {
                    try {
                        this.$refs.inputArrayInput.$el.children[0].children[0].focus();
                    } catch (error) {
                        console.error("Error in focus %o", error);
                    }
                }, 100);
            }
        },
        onInput() {
            this.$emit("change", this.arrayString);
        },
        setInput(value) {
            this.arrayString = value.filter((item) => item);
            if (value.length > this.arrayString.length) {
                this.onInput();
            }
        }
    }
};
</script>
