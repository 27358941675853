<template>
    <div class="w-100 h-100 position-relative">
        <div v-if="enabled" class="d-flex">
            <vs-input
                v-if="openInput"
                v-model="keyword"
                border
                shadow
                class="w-100 mw-100"
                :label="`Nueva ${label} (Separa por ',')`"
                :state="stateInputDark"
                @keyup.enter="addNewKeyword"
            />
            <vs-select
                v-else
                v-model="options"
                :label="label"
                filter
                multiple
                collapse-chips
                :state="stateInputDark"
                class="w-100 mw-100 border-custom"
                @input="onInput"
            >
                <vs-option
                    v-for="(item, index) in optionsBase"
                    :key="index"
                    :label="item"
                    :value="item"
                >
                    {{ item }}
                </vs-option>
            </vs-select>

            <vs-button icon border @click="changeActionKeys">
                {{ openInput ? "✔️" : "➕" }}
            </vs-button>
        </div>
        <div v-else class="d-flex flex-column h-100 w-100 mw-100">
            <div class="position-relative">
                <label class="left-13px"> {{ label }} </label>
            </div>

            <div class="d-flex flex-wrap px-2">
                <div
                    v-for="(item, index) in options"
                    :key="index"
                    class="bg-primary rounded mx-1 mt-1 px-1"
                    :label="item"
                    :value="item"
                >
                    <small>{{ item }}</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "OptionsSelector",
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        label: {
            type: String,
            default: () => "",
            required: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        },
        itemsCurrent: {
            type: Array,
            default: () => [],
            required: true
        },
        base: {
            type: Array,
            default: () => [],
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        options: [],
        openInput: false,
        keyword: "",
        optionsBase: []
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark", "backgroundColor"])
    },
    watch: {
        itemsCurrent(value) {
            this.options = [...value];
        }
    },
    mounted() {
        const optionsBase = [...this.base, ...this.itemsCurrent];
        this.optionsBase = optionsBase.filter(
            (value, index, self) =>
                self.findIndex((item) => item === value) === index
        );
        this.options = this.itemsCurrent;
    },
    methods: {
        addNewKeyword() {
            const options = this.keyword.split(", ");
            for (const keyAux of options) {
                this.optionsBase.unshift(keyAux);
            }
            this.keyword = "";
            this.openInput = false;
        },
        changeActionKeys() {
            if (this.openInput) {
                this.addNewKeyword();
            } else {
                this.openInput = true;
            }
        },
        onInput(value) {
            this.$emit("change", value);
        }
    }
};
</script>
