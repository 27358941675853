<template>
    <div class="border-bottom border-secondary w-100 mb-2">
        <div>
            <div
                class="col-12 mb-2 d-flex justify-content-between align-items-center"
            >
                <div class="d-flex flex-lg-row flex-column">
                    <div class="mx-1">
                        <div
                            v-if="
                                enabled &&
                                referenceComponent.id &&
                                $enabledEcommerceOptions
                            "
                            class="d-flex"
                        >
                            <vs-switch
                                v-model="referenceComponent.active"
                                class="bg-danger"
                                @input="onBlurActive"
                            >
                            </vs-switch>
                        </div>
                        <span v-else>
                            {{
                                referenceComponent.active
                                    ? "🟢&nbsp;"
                                    : "⚪&nbsp;"
                            }}
                        </span>
                    </div>
                    <div class="d-flex">
                        <strong> Ref :&nbsp; </strong>
                        <span class="text-success">
                            {{ referenceComponent.barcode }}
                            ({{ referenceComponent.id }})
                            {{
                                defaultReferenceId === referenceComponent.id
                                    ? " (Default)"
                                    : ""
                            }}
                        </span>
                    </div>
                </div>
                <div class="d-flex">
                    <vs-tooltip
                        v-model="showOptions"
                        bottom
                        shadow
                        not-hover
                        interactivity
                        border-thick
                    >
                        <vs-button
                            circle
                            icon
                            @click="showOptions = !showOptions"
                        >
                            <span> ⚙️ </span>
                        </vs-button>
                        <template #tooltip>
                            <div
                                class="autocomplete-items list-group text-left w-100"
                            >
                                <div
                                    v-if="
                                        $ability.hasScope(
                                            'reference:UpdateDefault'
                                        )
                                    "
                                    class="m-md-2 m-1"
                                >
                                    <button
                                        class="font-weight-bold btn-a"
                                        aria-label="Poner por defecto"
                                        @click="setDefaultComponent"
                                    >
                                        🎭&nbsp;Poner por defecto
                                    </button>
                                </div>
                                <div class="m-md-2 m-1">
                                    <button
                                        class="font-weight-bold btn-a"
                                        aria-label="Inventario en comercio"
                                        @click="goToInventory"
                                    >
                                        🧮&nbsp;Inventario en
                                        {{
                                            selectedMerchant.merchantType
                                                | merchantInfo("type")
                                        }}
                                        {{
                                            selectedMerchant.name
                                                | transformString("lower")
                                        }}
                                    </button>
                                </div>
                                <div
                                    v-if="
                                        $ability.hasScope(
                                            'inventory:ListByReference'
                                        )
                                    "
                                    class="m-md-2 m-1"
                                >
                                    <button
                                        class="font-weight-bold btn-a"
                                        aria-label="Inventario global"
                                        @click="listInventoryReferenceComponent"
                                    >
                                        🧮&nbsp;Inventario global
                                    </button>
                                </div>
                                <div
                                    v-if="$ability.hasScope('inventory:Create')"
                                    class="m-md-2 m-1"
                                >
                                    <button
                                        class="font-weight-bold btn-a"
                                        aria-label="Ir a inicio de app"
                                        @click="openInventoryRecord"
                                    >
                                        🧮&nbsp;Registrar inventario
                                    </button>
                                </div>
                                <div class="m-md-2 m-1">
                                    <button
                                        class="font-weight-bold btn-a"
                                        aria-label="Ir a la referencia"
                                        @click="goToReference"
                                    >
                                        🚀&nbsp;Ir a la referencia
                                    </button>
                                </div>
                                <div
                                    v-if="referenceComponent.barcode"
                                    class="m-md-2 m-1"
                                >
                                    <button
                                        class="font-weight-bold btn-a"
                                        aria-label="Códigoo de barras"
                                        @click="openBarcode"
                                    >
                                        📡&nbsp;Código de barras
                                    </button>
                                </div>
                                <div
                                    v-if="$ability.hasScope('product:Delete')"
                                    class="m-md-2 m-1"
                                >
                                    <button
                                        class="font-weight-bold btn-a-danger"
                                        aria-label="Eliminar referencia"
                                        @click="deleteReferenceComponent"
                                    >
                                        💀&nbsp;Eliminar referencia
                                    </button>
                                </div>
                            </div>
                        </template>
                    </vs-tooltip>
                    <vs-button
                        :class="
                            openReferenceId === referenceComponent.id
                                ? 'bg-success'
                                : 'bg-secondary'
                        "
                        icon
                        border
                        circle
                        @click="sendActive"
                    >
                        {{
                            openReferenceId === referenceComponent.id
                                ? "➰"
                                : "🔰"
                        }}
                    </vs-button>
                </div>
            </div>
        </div>

        <b-collapse
            ref="container"
            :visible="openReferenceId === referenceComponent.id"
        >
            <div class="d-flex flex-wrap">
                <div class="col-12 col-md-6 mt-5">
                    <barcode-scanner-input
                        v-model="referenceComponent.barcode"
                        check
                        :enabled="
                            enabled &&
                            (hasGlobalScope || !!referenceComponent.id)
                        "
                        :reference-id="referenceComponent.id"
                        class="opacity-false"
                    />
                </div>

                <div
                    v-if="'stock' in referenceComponent"
                    class="col-12 col-md-6 mt-5"
                >
                    <vs-input
                        v-model="referenceComponent.stock"
                        border
                        shadow
                        :state="stateEnabled"
                        type="number"
                        :disabled="!enabled || !hasGlobalScope"
                        label="Stock en total"
                        class="opacity-false"
                        @blur="onBlurStock"
                    />
                </div>

                <div class="col-12 col-md-6 mt-5">
                    <input-money-component
                        v-model="referenceComponent.price"
                        border
                        :enabled="enabledWithGlobal"
                        :label="`Precio sin descuento ${$siteType}`"
                        icon="💲"
                        :message-error="
                            introValidators.price && !referenceComponent.price
                                ? 'El precio sin descuento no puede ser cero'
                                : ''
                        "
                        class="opacity-false"
                        @blur="onBlurPrice"
                    />
                </div>

                <div class="col-12 col-md-6 mt-5">
                    <input-money-component
                        v-model="referenceComponent.priceOffer"
                        border
                        :enabled="enabledWithGlobal"
                        :label="`Precio final ${$siteType}`"
                        icon="💲"
                        :message-error="
                            referenceComponent.priceOffer >
                            referenceComponent.price
                                ? 'El precio final debe ser menor que el precio sin descuento'
                                : introValidators.priceOffer &&
                                  !referenceComponent.priceOffer
                                ? 'El precio en final no puede ser cero'
                                : ''
                        "
                        class="opacity-false"
                        @blur="onBlurPriceOffer"
                    />
                </div>

                <div
                    v-if="'cost' in referenceComponent"
                    class="col-12 col-md-6 mt-5"
                >
                    <input-money-component
                        v-model="referenceComponent.cost"
                        border
                        :enabled="enabled"
                        label="Costo"
                        icon="💲"
                        :message-error="
                            introValidators.cost && !referenceComponent.cost
                                ? 'El costo no puede ser cero'
                                : ''
                        "
                        class="opacity-false"
                        @blur="onBlurCost"
                    />
                </div>

                <div class="col-12 col-md-6 mt-5">
                    <input-money-component
                        v-model="referenceComponent.priceMerchants"
                        border
                        :enabled="enabled"
                        label="Precio al por mayor"
                        class="opacity-false"
                        icon="💲"
                    />
                </div>

                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="referenceComponent.minUnitsForPriceMerchants"
                        border
                        shadow
                        :state="stateEnabled"
                        type="number"
                        min="0"
                        class="opacity-false"
                        label="Unidades mínimas para precio al por mayor"
                        :disabled="!enabled || !hasGlobalScope"
                    />
                </div>

                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="referenceComponent.size"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Tamaño/Talla/Valor"
                        class="opacity-false"
                        :disabled="!enabled || !hasGlobalScope"
                    />
                </div>

                <div class="col-12 col-md-6 mt-5">
                    <color-input
                        v-model="color"
                        :enabled="enabledWithGlobal"
                        class="opacity-false"
                    />
                </div>

                <div
                    v-if="'alwaysStock' in referenceComponent"
                    class="col-12 col-md-6 mt-5"
                >
                    <checkbox-selector
                        v-model="referenceComponent.alwaysStock"
                        label="Siempre en inventario"
                        :enabled="enabledWithGlobal"
                    />
                </div>

                <images-manage
                    :id="`${referenceComponent.id}`"
                    v-model="images"
                    class="my-4"
                    :enabled="enabledWithGlobal"
                    message="producto"
                />

                <div
                    v-if="enabled"
                    class="col-12 mb-4 d-flex justify-content-center"
                >
                    <vs-button
                        v-if="!referenceComponent.id"
                        size="large"
                        :loading="isLoading"
                        @click="createReferenceComponent"
                    >
                        Crear referencia
                    </vs-button>

                    <vs-button
                        v-else
                        size="large"
                        :loading="isLoading"
                        @click="updateReferenceComponent"
                    >
                        Actualizar referencia
                    </vs-button>
                </div>
            </div>
        </b-collapse>

        <vs-dialog
            v-model="isOpenModalInventory"
            scroll
            overflow-hidden
            auto-width
            :loading="loadingCreateInventory"
        >
            <template #header>
                <div class="d-flex mt-2">
                    <div
                        class="col-10 mx-auto text-center d-flex flex-column align-items-center"
                    >
                        <span class="w-100 h4">
                            Registro de invetario para la tienda
                        </span>
                        <strong class="text-primary h4">
                            {{ selectedMerchant.name }}
                        </strong>
                    </div>
                </div>
            </template>
            <div class="con-content">
                <inventory-by-reference
                    :price-offer="referenceComponent.priceOffer"
                    :cost-initial="referenceComponent.cost"
                    @create="recordInventory"
                />
            </div>
        </vs-dialog>

        <vs-dialog
            v-model="isOpenModalGetInventory"
            scroll
            overflow-hidden
            auto-width
            :loading="isLoadingGetInventory"
        >
            <template #header>
                <div class="d-flex flex-column mx-3 my-4">
                    <span class="h3">
                        Inventario para referencia
                        <span class="text-primary">
                            # {{ referenceComponent.id }}
                        </span>
                    </span>
                    <small>
                        Color {{ referenceComponent.color }} | Tamaño
                        {{ referenceComponent.size }}
                    </small>
                </div>
            </template>
            <div class="con-content">
                <inventory-by-reference-table
                    :inventories="inventories"
                    :stock-active="stockActive"
                />
            </div>
        </vs-dialog>

        <vs-dialog v-model="isOpenBarcode" scroll overflow-hidden auto-width>
            <template #header>
                <div class="d-flex mt-2 w-100">
                    <div
                        class="col-10 mx-auto text-center d-flex flex-column align-items-center"
                    >
                        <span class="w-100 h4"> Código de barras </span>
                    </div>
                </div>
            </template>
            <div class="con-content d-flex justify-content-center">
                <barcode-generator :barcode="referenceComponent.barcode">
                    <template #top>
                        <div class="d-flex flex-wrap align-items-center mb-1">
                            <span>
                                {{ product.name | cut(38) }}
                            </span>
                            &nbsp;
                            <div
                                class="w-6 h-6 border rounded-circle border-dark d-flex justify-content-center"
                            >
                                <span>
                                    {{ referenceComponent.size }}
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #bottom>
                        <span class="text-lg">
                            {{ referenceComponent.barcode }}
                        </span>
                    </template>
                </barcode-generator>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import InventoryByReference from "@/components/inventories/InventoryByReference.vue";
import InventoryByReferenceTable from "@/components/inventories/InventoryByReferenceTable.vue";
import BarcodeGenerator from "@/components/utils/BarcodeGenerator.vue";
import BarcodeScannerInput from "@/components/utils/BarcodeScannerInput.vue";
import CheckboxSelector from "@/components/utils/CheckboxSelector.vue";
import ColorInput from "@/components/utils/ColorInput.vue";
import ImagesManage from "@/components/utils/ImagesManage.vue";
import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "ReferenceManage",
    components: {
        ColorInput,
        ImagesManage,
        BarcodeGenerator,
        CheckboxSelector,
        BarcodeScannerInput,
        InputMoneyComponent,
        InventoryByReference,
        InventoryByReferenceTable
    },
    props: {
        product: {
            type: Object,
            default: () => ({
                name: "Cargando.."
            }),
            required: false
        },
        reference: {
            default: () => ({
                active: true,
                minUnitsForPriceMerchants: 2,
                priceMerchants: 0,
                color: "blanco",
                colorRef: "#fff",
                createdAt: "2021-10-26T00:27:59.146Z",
                isDefault: true,
                id: 21,
                alwaysStock: false,
                barcode: "",
                images: [],
                cost: 38000,
                price: 50000,
                priceOffer: 40000,
                productId: 17,
                size: "unico",
                stock: 20,
                updatedAt: "2021-10-26T00:27:59.146Z"
            }),
            type: Object,
            required: true
        },
        openReferenceId: {
            type: Number,
            default: 0,
            required: false
        },
        defaultReferenceId: {
            type: Number,
            default: 0,
            required: false
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: [
        "remove",
        "created",
        "update",
        "changeActive",
        "goToReference",
        "setDefault"
    ],
    data: () => ({
        stockActive: 0,
        showColor: false,
        isLoading: false,
        showOptions: false,
        loaderInstance: null,
        hasGlobalScope: false,
        isOpenBarcode: false,
        isOpenModalInventory: false,
        isLoadingGetInventory: false,
        loadingCreateInventory: false,
        isOpenModalGetInventory: false,
        inventories: [],
        introValidators: {
            stock: false,
            priceOffer: false,
            price: false,
            cost: false
        },
        images: [],
        color: {
            color: "blanco",
            colorRef: "#fff"
        },
        referenceComponent: {
            id: 0,
            active: true,
            minUnitsForPriceMerchants: 1,
            stock: 20,
            barcode: "",
            color: "blanco",
            colorRef: "#fff",
            price: 0,
            priceOffer: 0,
            priceMerchants: 0,
            cost: 0,
            productId: 17,
            size: "unico",
            isDefault: true,
            createdAt: "2021-10-26T00:27:59.146Z",
            updatedAt: "2021-10-26T00:27:59.146Z",
            images: []
        }
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        enabledWithGlobal() {
            return this.enabled && this.hasGlobalScope;
        },
        stateEnabled() {
            return this.enabled ? this.stateInputDark : "";
        }
    },
    mounted() {
        this.images = [...this.reference.images].sort(
            (a, b) => a.order.order - b.order.order
        );
        this.color = {
            color: this.reference.color,
            colorRef: this.reference.colorRef
        };
        this.referenceComponent = this.reference;
        this.hasGlobalScope = this.$ability.hasScope("reference:Update_");
    },
    methods: {
        ...mapActions("products", [
            "createReference",
            "updateReference",
            "deleteReference"
        ]),
        ...mapActions("inventories", [
            "createInventory",
            "listInventoryByReference"
        ]),
        async listInventoryReferenceComponent() {
            this.isLoading = true;
            this.isOpenModalGetInventory = true;
            this.isLoadingGetInventory = true;
            try {
                const { items, stockActive } =
                    await this.listInventoryByReference({
                        id: this.referenceComponent.id
                    });
                this.inventories = items;
                this.stockActive = stockActive;
            } catch (error) {
                this.isOpenModalGetInventory = false;
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
                this.isLoadingGetInventory = false;
            }
        },
        async createReferenceComponent() {
            if (!this.validateReference()) {
                return;
            }
            this.isLoading = true;
            try {
                const resultReference = await this.createReference({
                    ...this.referenceComponent,
                    stock: 0,
                    colorRef: this.color.colorRef,
                    color: this.color.color,
                    images: this.images.map((item) => ({
                        id: item.id,
                        order: item.order.order
                    }))
                });
                this.referenceComponent.id = resultReference.id;
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Creación exitosa",
                    text: `Id: ${this.referenceComponent.id}`,
                    icon: "success",
                    showCloseButton: true
                });
                this.$emit("created", {
                    ...this.referenceComponent,
                    images: this.images
                });
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async toggleActiveReferenceComponent() {
            this.isLoading = true;
            try {
                await this.updateReference({
                    active: this.referenceComponent.active,
                    id: this.referenceComponent.id
                });
                this.$emit("update", this.referenceComponent);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async updateReferenceComponent() {
            if (!this.validateReference()) {
                return;
            }
            this.isLoading = true;
            try {
                await this.updateReference({
                    ...this.referenceComponent,
                    colorRef: this.color.colorRef,
                    color: this.color.color,
                    ...{
                        ...(!this.introValidators.stock
                            ? { stock: null }
                            : null)
                    },
                    images: this.images.map((item) => ({
                        id: item.id,
                        order: item.order.order
                    }))
                });
                this.$emit("update", this.referenceComponent);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async deleteReferenceComponent() {
            this.showOptions = false;
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: "Seguro ?",
                text: "Recuerda se va a eliminar la referencia y la relación de imágenes",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33ddd",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });
            if (dialogResult.isConfirmed) {
                this.isLoading = true;
                this.loaderInstance = this.$vs.loading({ type: "circles" });
                try {
                    await this.deleteReference({
                        referenceId: this.referenceComponent.id
                    });
                    this.$vs.notification({
                        title: `Referencia # ${this.referenceComponent.id} eliminada`,
                        color: "primary",
                        icon: "🚧"
                    });
                    this.$emit("remove");
                } catch (error) {
                    this.loaderInstance.close();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                    const sound = this.$sounds.get("error");
                    sound.volume(0.9);
                    sound.play();
                } finally {
                    this.loaderInstance.close();
                    this.isLoading = false;
                }
            }
        },
        async setDefaultComponent() {
            this.showOptions = false;
            setTimeout(() => {
                this.$emit("setDefault", this.referenceComponent.id);
            }, 100);
        },
        async recordInventory({ costNew, quantity }) {
            this.loadingCreateInventory = true;
            try {
                await this.createInventory({
                    merchantType: this.selectedMerchant.merchantType,
                    merchantId: this.selectedMerchant.merchantId,
                    referenceId: this.referenceComponent.id,
                    costNew,
                    quantity
                });
                this.isOpenModalInventory = false;
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
            } catch (error) {
                this.isOpenModalInventory = false;
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                this.isOpenModalInventory = true;
            } finally {
                this.loadingCreateInventory = false;
            }
        },
        goToInventory() {
            window
                .open(
                    `/app/inventories/${this.selectedMerchant.merchantType}/${this.selectedMerchant.merchantId}/detail/${this.referenceComponent.id}`,
                    "_blank"
                )
                .focus();
        },
        goToReference() {
            this.$emit(
                "goToReference",
                `?reference=${this.referenceComponent.id}`
            );
        },
        sendActive() {
            if (this.referenceComponent.id === this.openReferenceId) {
                this.$emit("changeActive", 0);
            } else {
                this.$emit("changeActive", this.referenceComponent.id);
            }
        },
        openInventoryRecord() {
            this.showOptions = false;
            this.isOpenModalInventory = true;
        },
        validateReference() {
            this.introValidators = {
                ...this.introValidators,
                price: true,
                priceOffer: true,
                stock: true
            };
            if (
                this.color.color === "" ||
                this.color.colorRef === "" ||
                this.referenceComponent.size === "" ||
                !this.referenceComponent.priceOffer ||
                !this.referenceComponent.price ||
                this.referenceComponent.priceOffer >
                    this.referenceComponent.price
            ) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Referencia invalida",
                    text: "Revisa por favor que todo este completo",
                    icon: "info"
                });
                return false;
            }
            return true;
        },
        openBarcode() {
            this.isOpenBarcode = true;
        },
        onBlurCost() {
            this.introValidators.cost = true;
            this.$emit("update", this.referenceComponent);
        },
        onBlurPriceOffer() {
            this.introValidators.priceOffer = true;
            this.$emit("update", this.referenceComponent);
        },
        onBlurPrice() {
            this.introValidators.price = true;
            this.$emit("update", this.referenceComponent);
        },
        onBlurStock() {
            this.introValidators.stock = true;
            this.$emit("update", this.referenceComponent);
        },
        onBlurActive() {
            this.toggleActiveReferenceComponent();
        }
    }
};
</script>
