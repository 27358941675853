export const gendersCodes = {
    boy_0: "Niño menor de dos años",
    girl_0: "Niña menor de dos años",
    girl_2_7: "Niña de 2 a 7 años",
    boy_2_7: "Niño de 2 a 7 años",
    girl_8_16: "Niña de 8 a 16 años",
    boy_8_16: "Niño de 8 a 16 años",
    woman: "Femenino",
    man: "Masculino",
    unisex: "Unisex"
};
