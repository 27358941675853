<template>
    <div class="d-flex flex-column align-items-center">
        <div class="d-flex flex-wrap mb-3">
            <div class="col-6 col-md-4 d-flex flex-column">
                <span class="text-success"> Costo actual </span>
                <span>{{ costInitial | money }}</span>
            </div>
            <div class="col-6 col-md-4 d-flex flex-column">
                <span class="text-success"> Margen actual </span>
                <span>{{ revenueCurrent | percent }}</span>
            </div>
            <div class="col-6 col-md-4 d-flex flex-column">
                <span class="text-success"> Precio u. actual </span>
                <span> {{ priceOffer | money }}</span>
            </div>
            <div v-show="costTemp" class="col-6 col-md-4 d-flex flex-column">
                <span class="text-success"> {{ messageCost }} </span>
                <span>{{ messageValue }}</span>
            </div>
            <div v-show="costTemp" class="col-6 col-md-4 d-flex flex-column">
                <span class="text-success"> Margen bruto </span>
                <span
                    :class="{
                        'text-success': revenue > 0.3,
                        'text-danger': revenue < 0.08
                    }"
                >
                    {{ revenue | percent }}
                </span>
            </div>
        </div>
        <div class="col-12 mt-3 d-flex align-items-end">
            <input-money-component
                v-model="costTemp"
                enabled
                border
                :label="isInputCostUnit ? 'Costo de unidad' : 'Costo de lote'"
                :message-error="
                    !isValidCost & introValidators.costNew
                        ? 'Costo no puede ser $0'
                        : ''
                "
                class="w-100 mw-100"
                @blur="validateCost"
            />
            <vs-tooltip>
                <vs-button
                    icon
                    border
                    :active="isInputCostUnit"
                    @click="updateUnitCostSw"
                >
                    {{ isInputCostUnit ? "1️⃣" : "🔢" }}
                </vs-button>
                <template #tooltip>
                    <div class="content-tooltip">
                        <span v-if="isInputCostUnit">
                            Registrar con costo de lote
                        </span>
                        <span v-else>Registrar con costo de unidad</span>
                    </div>
                </template>
            </vs-tooltip>
        </div>
        <div class="col-12 mt-5">
            <vs-input
                v-model="inventoryComponent.quantity"
                border
                shadow
                type="number"
                min="1"
                label="Cantidad"
                @input="calculateUnitCost"
                @blur="calculateUnitCost"
            />
        </div>
        <div class="col-12 mt-4 d-flex justify-content-center">
            <vs-button @click="createInventoryInput">
                Registrar inventario
            </vs-button>
        </div>
    </div>
</template>

<script>
import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "InventoryByReference",
    components: { InputMoneyComponent },
    props: {
        costInitial: {
            default: () => 0,
            type: Number,
            required: false
        },
        priceOffer: {
            default: () => 1,
            type: Number,
            required: false
        }
    },
    data: () => ({
        inventoryComponent: {
            costNew: 1000,
            quantity: 1
        },
        costTemp: 0,
        revenue: 0,
        revenueCurrent: 0,
        isInputCostUnit: true,
        messageCost: "Costo de lote",
        messageValue: 0,
        introValidators: { costNew: false }
    }),
    computed: {
        isValidCost() {
            return this.inventoryComponent.costNew > 0;
        }
    },
    mounted() {
        const costNew = this.costTempInitial || this.priceOffer * 0.7;
        this.costTemp = +costNew;
        this.messageValue = this.$options.filters.money(costNew);
        this.inventoryComponent.costNew = +costNew;
        this.revenueCurrent = 1 - costNew / this.priceOffer;
        this.revenue = +this.revenueCurrent;
    },
    methods: {
        validateCost() {
            this.introValidators.costNew = true;
            this.calculateUnitCost();
        },
        updateUnitCostSw() {
            if (this.isInputCostUnit) {
                this.costTemp =
                    this.costTemp * this.inventoryComponent.quantity;
            } else {
                this.costTemp =
                    this.costTemp / this.inventoryComponent.quantity;
            }
            this.isInputCostUnit = !this.isInputCostUnit;
            this.calculateUnitCost();
        },
        calculateUnitCost() {
            let costUnit = 0;
            if (this.isInputCostUnit) {
                costUnit = +this.costTemp;
                this.inventoryComponent.costNew = +costUnit;
                this.messageCost = `Costo de lote`;
                this.messageValue = this.$options.filters.money(
                    costUnit * this.inventoryComponent.quantity
                );
            } else {
                costUnit = this.costTemp / this.inventoryComponent.quantity;
                this.inventoryComponent.costNew = +costUnit;
                this.messageCost = `Costo de unidad`;
                this.messageValue = this.$options.filters.money(costUnit);
            }
            this.revenue = 1 - costUnit / this.priceOffer;
        },
        createInventoryInput() {
            if (!this.validateInventoryInput()) {
                return this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Entrada de inventario invalida",
                    text: "Revisa por favor que todo este completo",
                    icon: "info"
                });
            }
            this.$emit("create", {
                costNew: parseInt(this.inventoryComponent.costNew),
                quantity: parseInt(this.inventoryComponent.quantity)
            });
        },
        validateInventoryInput() {
            this.introValidators = {
                costNew: true
            };
            return this.isValidCost;
        }
    }
};
</script>
