<template>
    <div id="list-productTypes-component" class="w-100 h-100">
        <vs-select
            v-if="enabled"
            v-model="option"
            label-placeholder="Tipo de producto"
            shadow
            border
            :state="stateInputDark"
            name="itemCurrent"
            class="w-100 mw-100 border-custom"
            @input="onInput"
        >
            <vs-option
                v-for="(value, key) in $data.$productTypes"
                :key="key"
                :label="value"
                :value="key"
            >
                {{ value }}
            </vs-option>
        </vs-select>
        <div v-else class="d-flex flex-column h-100 w-100 mw-100">
            <div class="position-relative">
                <label class="left-13px"> Tipo de producto </label>
            </div>
            <div class="d-flex px-2 mt-3 left-13px align-items-center h-100">
                <span class="ml-2">
                    {{
                        $data.$productTypes[itemCurrent] ||
                        $data.$productTypes.article
                    }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { productTypes } from "@/common/fields/product-types";

export default {
    name: "TypeProductSelector",
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            default: () => "article",
            type: String,
            require: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        option: "",
        $productTypes: productTypes
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark"])
    },
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
    },
    methods: {
        onInput() {
            this.$emit("change", this.option);
        },
        setInput(value) {
            this.option = value;
        }
    }
};
</script>
