<template>
    <div id="list-brands-component" class="w-100 h-100">
        <div
            v-if="enabled"
            class="d-flex align-items-center justify-content-between"
        >
            <span v-if="isLoading"> Cargando...</span>
            <vs-select
                v-else
                v-model="option"
                label="Configuración logística"
                border
                shadow
                filter
                name="brand"
                :state="stateInputDark"
                class="w-100 mw-100 border-custom"
                @input="onInput"
            >
                <vs-option
                    v-for="(item, i) in options"
                    :key="i"
                    :label="item.name"
                    :value="item.slug"
                >
                    {{ item.name }}
                </vs-option>
            </vs-select>
            <vs-button circle icon floating @click="reloadItems">
                <reload-icon />
            </vs-button>
        </div>
        <div v-else class="d-flex flex-column h-100 w-100 mw-100">
            <div class="position-relative">
                <label class="left-13px"> Configuración logística </label>
            </div>
            <div class="d-flex px-2 align-items-center h-100">
                <span class="ml-2">{{ itemCurrent.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";

export default {
    name: "LogisticConfigurationSelector",
    components: { ReloadIcon },
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            default: () => ({
                name: "",
                slug: ""
            }),
            type: Object,
            require: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        },
        fill: {
            default: () => false,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        option: "",
        options: [],
        isLoading: true
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapGetters("control", ["stateInputDark", "backgroundColor"])
    },
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
        if (this.fill) {
            this.reloadItems(true);
        }
    },
    methods: {
        ...mapActions("logisticConfigurations", [
            "listAllLogisticConfigurations"
        ]),
        async reloadItems(complete = true) {
            this.isLoading = true;
            try {
                this.options = await this.listAllLogisticConfigurations({
                    complete
                });
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        setInput(value) {
            const aux = this.options.find((item) => item.slug === value.slug);
            if (!aux) {
                this.options = [...this.options, value];
            }
            this.option = value.slug ?? "";
            this.isLoading = false;
        },
        onInput() {
            const aux = this.options.find((item) => this.option === item.slug);
            if (aux) {
                this.$emit("change", aux);
            }
        }
    }
};
</script>
