<template>
    <div class="my-2 mx-2 w-100">
        <vs-card-group>
            <vs-card v-for="(item, index) in itemsCurrent" :key="index">
                <template #title>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h3 class="mx-80">{{ item.name }}</h3>
                        <vs-button
                            v-if="enabled"
                            danger
                            :disabled="isLoadingComponent"
                            icon
                            @click="deleteCategory(index)"
                        >
                            🗑️
                        </vs-button>
                    </div>
                </template>
                <template #text>
                    <span>
                        {{ item.name }}
                        &nbsp;
                        <small>({{ item.slug }})</small>
                    </span>
                </template>
            </vs-card>
        </vs-card-group>
        <div v-if="enabled" class="d-flex flex-wrap">
            <div class="col-12 d-flex justify-content-end">
                <vs-button
                    :disabled="isLoadingComponent"
                    circle
                    icon
                    @click="openNewCategory"
                >
                    ➕
                </vs-button>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <slot name="action"></slot>
            </div>
        </div>
        <div>
            <vs-dialog
                v-model="selectCategory"
                scroll
                overflow-hidden
                auto-width
                :loading="isLoadingComponent"
            >
                <template #header>
                    <div class="d-flex flex-column">
                        <div
                            class="mx-4 mt-2 d-flex flex-column flex-lg-row align-items-center"
                        >
                            <h3 class="text-center">
                                🏷️ Seleccione una categoría
                            </h3>
                            <vs-button
                                circle
                                icon
                                floating
                                @click="loadCategories(true)"
                            >
                                <reload-icon />
                            </vs-button>

                            <vs-button
                                :disabled="!selectedItems.length"
                                icon
                                circle
                                @click="pushNewCategory"
                            >
                                ✔️
                            </vs-button>
                        </div>
                    </div>
                </template>
                <div class="con-content">
                    <div
                        v-if="items.length"
                        class="d-flex flex-column justify-content-center align-items-center"
                    >
                        <button
                            v-for="(item, index) in items"
                            :key="index"
                            class="d-flex flex-column btn btn-primary w-75 w-min-30 my-2 text-left"
                            :class="
                                selectedItems.includes(item.slug)
                                    ? 'border-3 border-highlight'
                                    : ''
                            "
                            @click="selectionCategory(item.slug)"
                        >
                            <h3>{{ item.name }}</h3>
                            <p>{{ item.shortDescription }}</p>
                        </button>
                    </div>
                    <div v-else>
                        <span class="font-weight-bold text-md">
                            Nuestro sistema no encuentra resultados 😢
                        </span>
                    </div>
                    <div class="row d-flex justify-content-center mt-4">
                        <vs-button
                            v-if="pagesNumber > currentPage"
                            @click="loadMoreCategories"
                        >
                            Cargar más
                        </vs-button>
                    </div>
                </div>
            </vs-dialog>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "CategoriesCurrentManage",
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            default: () => [],
            type: Array,
            require: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        selectCategory: false,
        selectedItems: [],
        items: [],
        isLoadingComponent: false,
        currentPage: 1,
        pagesNumber: 0
    }),
    computed: { ...mapGetters("control", ["backgroundColor"]) },
    watch: {
        itemsCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemsCurrent);
    },
    methods: {
        ...mapActions("categories", ["listCategoriesPaginated"]),
        ...mapActions("products", ["updateProductsCategories"]),
        async openNewCategory() {
            this.selectCategory = true;
            await this.loadCategories();
        },
        async loadMoreCategories() {
            this.currentPage = this.currentPage + 1;
            await this.loadCategories();
        },
        async loadCategories(required = true) {
            this.isLoadingComponent = true;
            try {
                const { items, pagination } =
                    await this.listCategoriesPaginated({
                        page: this.currentPage,
                        required
                    });
                this.items = items;
                if (pagination) {
                    this.pagesNumber = pagination.pagesNumber;
                }
            } catch (error) {
                this.selectCategory = false;
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoadingComponent = false;
            }
        },
        selectionCategory(slug) {
            this.selectedItems.push(slug);
        },
        pushNewCategory() {
            let categoriesAux = this.items.filter((i) =>
                this.selectedItems.includes(i.slug)
            );
            if (categoriesAux.length) {
                const categoriesResult = JSON.parse(
                    JSON.stringify(this.itemsCurrent)
                );

                categoriesAux = [...categoriesResult, ...categoriesAux];

                categoriesAux = categoriesAux.filter(
                    (value, index, self) =>
                        self.findIndex((item) => item.slug === value.slug) ===
                        index
                );
                this.selectCategory = false;
                this.$emit("change", categoriesAux);
            }
        },
        deleteCategory(fromIndex) {
            let categoriesResult = [...this.itemsCurrent];
            categoriesResult.splice(fromIndex, 1);
            this.$emit("change", categoriesResult);
        },
        setInput(value) {
            this.selectedItems = value.map((item) => item.slug);
        }
    }
};
</script>

<style scoped>
.vs-card__group .vs-card__group-cards .vs-card-content {
    min-width: 15rem;
    margin-left: 20px;
}
.vs-navbar-content {
    z-index: 1000 !important;
}
</style>
